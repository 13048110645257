<template>
    <ProductsTrendingMB v-if="layout == 4 " />
    <ProductsTrendingPC v-else />
</template>

<script>
    import ProductsTrendingMB from '@/pages/mb/ProductsTrending';
    import ProductsTrendingPC from '@/pages/pc/ProductsTrending';
    import { provide, reactive } from 'vue';
    import { useRoute } from 'vue-router';

    export default {
        components:{
            ProductsTrendingMB,
            ProductsTrendingPC
        },
        setup() {
            const route = useRoute();
            const type = 'treding';
            const params = reactive({
                page : route.query.page ? route.query.page : 1,
                type_category : route.query.type_category ? route.query.type_category : '',
                type
            });

            provide('type' , type);
            provide('params' , params);
        },
    }
</script>