<template>
  <main class="product-level1-page page-new-product">
    <BackHeader>
      <template v-slot:center>
        <h2 class="text-grey-lg title-header-category">
          {{ state.title }}
        </h2>
      </template>
      <template v-slot:right>
        <div class="back-header-right-side">
          <router-link
            :to="{ name: 'search' }"
            class="search-icon dosiin_search-header-btn"
          >
            <i class="dsi dsi-search"></i>
          </router-link>
        </div>
      </template>
    </BackHeader>

    <div class="product-page-header_wrap">
      <h1 class="title" v-text="state.title"></h1>
      <h2 class="subtitle" v-text="state.des"></h2>
    </div>
    <div class="page-nav-bar-sticky">
      <div
        class="page-nav-bar dosiin_page-nav-bar dosiin_filter-result-nav-bar filter-result-nav-bar"
      >
        <div class="total-result" style="display: flex">
          <b class="total-product" v-text="numberFormat(state.totalProducts)"></b>
          &nbsp;sản phẩm
        </div>
      </div>
    </div>
    <div class="page-section">
      <ProductLoaderApiData
        :type="type"
        :query="params"
        gridClass="grid-50"
        @total-products="updateTotals"
      />
    </div>

    <!-- <Intersection
            @onIntersection="onIntersectionProductBestSelling()"
        >
            <ProductsSliderHorizon 
                wrapperClass="page-section overflow"
                title="Sản phẩm bán chạy"
                gridClass="grid-33"
                :products="state.productsBestSelling"
                :options="{
                    slidesPerView : 2.5,
                    slidesPerGroup : 2,
                    spaceBetween   :9,
                }"
            />
        </Intersection> -->
  </main>
</template>

<script>
import { reactive, inject } from "vue";
import BackHeader from "@/components/common/BackHeader";
import ProductLoaderApiData from "@/components/product/ProductLoaderApiData";
import ProductsSliderHorizon from "@/components/sliders/ProductsHorizon";
// import DataService from "@/services/dosiin";
// import { serialize } from "@/utlis/serialize";

export default {
  components: {
    BackHeader,
    ProductLoaderApiData,
    ProductsSliderHorizon,
  },
  setup() {
    const type = inject("type");
    const params = inject("params");
    const t = inject("t");
    const state = reactive({
      title: t("products.products_trending"),
      des: "Các sản phẩm nổi bật nhất ở Dosi-in",
      totalProducts: 0,
      productsBestSelling: [],
    });

    // async function onIntersectionProductBestSelling () {
    //     const response = await DataService.fetchData(serialize({ type : 'best_seller' , limit : 21, distinct_company : true}));
    //     if(response.data){
    //         state.productsBestSelling = Object.values(response.data.products);
    //     }
    // }

    const updateTotals = (total) => {
      state.totalProducts = total;
    };

    return {
      state,
      type,
      params,
      updateTotals,
      // onIntersectionProductBestSelling
    };
  },
};
</script>
